<template>
  <ObserveVisibility
    root-margin="300px"
    once
    @observe:visible="onVisibilityChanged"
  >
    <OCarouselOffer
      v-show="loading || products?.length"
      ref="observeEntity"
      class="o-product-carousel-offer"
      :title="!loading && !products?.length ? undefined : title"
      :title-level="titleLevel"
      :subtitle="subtitle"
      :link-href="linkHref"
      :link-text="linkText"
      :is-closed="isClosed"
      :break-points="breakPoints"
      :items-per-slide="itemsPerSlide"
      :with-background="withBackground"
      :loop="loop"
      :transition="transition"
      :arrow-size="arrowSize"
    >
      <template v-if="loading">
        <AProductCardSkeleton
          v-for="n in loadingItems"
          :key="n"
          variant="carousel"
        />
      </template>
      <template v-else>
        <MProductCarouselItem
          v-for="product in products"
          :key="product.code"
          :value="product"
          :variant="variant"
          :is-favorite="isProductFavorite(product?.id)"
          :meta-anchor="metaAnchor"
          :availability="productAvailability[product.id]"
          @toggle:favorite="toggleFavorite(product.id)"
          @set:detail-current-product="setDetailCurrentProduct"
          @update:cart-count="$emit('update:cartCount', $event)"
        />
      </template>
    </OCarouselOffer>
  </ObserveVisibility>
</template>

<script lang="ts" setup>
import { ref, defineComponent, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useFavoritesStore } from '@/stores/favorites'
import { useProducts } from '@/stores/products'
import { useProductStore } from '@/stores/product'

import type { Ref, PropType } from 'vue'
import type { Product } from '@winestyle/api-client/src/ts/api/catalog/v1/product_pb.js'
import type { Breakpoints } from '@/composables/device'
import type { IconSize } from '@/utils/icon-types-static'
import type { Availability } from '@/modules/nuxt-api/models/Product'
import type { HeaderLevel } from '@/components/ui-kit/a-heading/types'
import type { ProductCarouselItemVariant } from '@/components/molecules/ProductCarouselItem/MProductCarouselItem.vue'

import AProductCardSkeleton from '@/components/atoms/ProductCardSkeleton/AProductCardSkeleton.vue'
import MProductCarouselItem from '@/components/molecules/ProductCarouselItem/MProductCarouselItem.vue'
import OCarouselOffer from '@/components/organisms/CarouselOffer/OCarouselOffer.vue'
import ObserveVisibility from '@/components/ui-kit/u-observe-visibility/ObserveVisibility.vue'

defineComponent({ name: 'OProductCarouselOffer' })

const props = defineProps({
  linkHref: {
    type: String,
    default: ''
  },
  linkText: {
    type: String,
    default: ''
  },
  subtitle: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: undefined
  },
  titleLevel: {
    type: [String, Number] as PropType<HeaderLevel>,
    default: undefined
  },
  withBackground: {
    type: Boolean,
    default: false
  },
  isClosed: {
    type: Boolean,
    default: false
  },
  loop: {
    type: Boolean,
    default: false
  },
  transition: {
    type: Number,
    default: 500
  },
  arrowSize: {
    type: String as PropType<IconSize>,
    default: 'lg'
  },
  itemsPerSlide: {
    type: Number,
    default: 0
  },
  breakPoints: {
    type: Object as PropType<Record<Breakpoints, number>>,
    default: () => ({
      mobile: 7,
      laptop: 5,
      desktop: 6
    })
  },
  products: {
    type: Array as PropType<Product.AsObject[]>,
    default: () => []
  },
  variant: {
    type: String as PropType<ProductCarouselItemVariant>,
    default: undefined
  },
  loadingItems: {
    type: Number,
    default: 8
  },
  metaAnchor: {
    type: String,
    default: undefined
  },
  loading: Boolean
})
defineEmits(['update:cartCount'])

const route = useRoute()
const nuxtApp = useNuxtApp()
const isVisible = ref(false)

const { toggleProductFavorite, isProductFavorite } = useFavoritesStore()
const productStore = useProductStore()

const productAvailability: Ref<Record<number, Availability | undefined>> = ref({})

const setDetailCurrentProduct = (product: Product.AsObject): void => {
  const title = 'Переход на бест. продукт со страницы ' + route.path
  const url = `products/${product.code}.html`

  if (nuxtApp.$clicky) {
    nuxtApp.$clicky.log(url, title)
  }

  productStore.currentProduct.value = product
}

const getAvailability = async () => {
  if (!props.products?.length) { return }

  const idsList = props.products.map?.(item => item.id)
  productAvailability.value = await useProducts().getProductAvailability(idsList)
}

const onVisibilityChanged = () => {
  isVisible.value = true
  getAvailability()
}

function toggleFavorite (productId: Product.AsObject['id']) {
  toggleProductFavorite(productId)
}

watch(() => props.products, () => {
  if (!isVisible.value) { return }

  getAvailability()
})
</script>

<style lang="postcss">
.o-product-carousel-offer {
  margin-top: var(--spacer-7xl);
  scroll-margin-top: 6rem;

  & .carousel__list > div {
    height: 100%;
  }

  .carousel__arrow {
    top: 184px;
  }

  @media (--screen-xs) {
    margin-top: var(--spacer-4xl);
    scroll-margin-top: 3rem;

    & .carousel {
      --carousel-item-width: 8.5rem;
    }
  }
}
</style>
